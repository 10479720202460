import React, { useState, useEffect } from 'react';
import './IntroText.css';

const IntroText = () => {
  const sentences = React.useMemo(
    () => [
      'AI driven token!',
      'Really fun and engaging',
      'The art, website, and other components built with the help of AI',
      'We are pumped about this token!',
    ],
    []
  );

  const [text, setText] = useState('');
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    if (typing) {
      if (text === sentences[sentenceIndex]) {
        setTimeout(() => {
          setTyping(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setText((prevText = '') => prevText + sentences[sentenceIndex][prevText.length]);
        }, 100);
      }
    } else {
      setTimeout(() => {
        setSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        setText('');
        setTyping(true);
      }, 2000);
    }
  }, [text, sentenceIndex, typing, sentences]);

  return (
    <div className="intro-text-container">
      <div className="intro-text">
        <div className="intro-text__border"></div>
        <div className="intro-text__content">
          {text}
          <span className="intro-text__cursor" />
        </div>
      </div>
    </div>
  );
};

export default IntroText;
