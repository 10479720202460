import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './components/navbar/Navbar';
import HomePage from './components/homepage/HomePage';
import Footer from './components/footer/Footer';
import HomeImage from './components/homeimage/HomeImage';

const App = () => {
  return (
    <Router>
      <Container>
        <HomePage/>
        <HomeImage/>
        <Navbar />
        <Routes>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/aboutus" component={AboutPage} />
          <Route exact path="/whitepaper" component={WhitepaperPage} />
          <Route exact path="/roadmap" component={RoadmapPage} />
          <Route exact path="/team" component={TeamPage} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
};

const Container = styled.div`
  font-family: sans-serif;
`;

const WhitepaperPage = () => {
  return <Container>Whitepaper</Container>;
};

const RoadmapPage = () => {
  return <Container>Road Map</Container>;
};

const AboutPage = () => {
  return <Container>About Page</Container>;
};

const TeamPage = () => {
  return <Container>Team Page</Container>;
};

export default App;
