import React from 'react';
import './HomeImage.css';

const HomeImage = () => {
  return (
    <div className="HomeImage">
      <img src={require("../../images/gif_no_bg.gif")} alt="spinning AI and alien coin" />
    </div>
  );
};

export default HomeImage;
