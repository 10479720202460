import React from 'react';
import styled from 'styled-components';
import './Navbar.css';

const HamburgerLine = styled.span`
  &:nth-child(2) {
    opacity: ${({ cross }) => (cross ? '0' : '1')};
  }

  &:nth-child(1) {
    transform: ${({ cross }) => (cross ? 'rotate(45deg) translate(7px, 7px)' : 'none')};
  }

  &:nth-child(3) {
    transform: ${({ cross }) => (cross ? 'rotate(-45deg) translate(-7px, -7px)' : 'none')};
  }

  z-index: 1;
`;

const NavLinks = styled.div`
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;

const NavLinkWrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

const NavbarLinks = () => {
  const [showLinks, setShowLinks] = React.useState(false);
  const [cross, setCross] = React.useState(false);

  const toggleLinks = () => {
    setShowLinks((showLinks) => !showLinks);
    setCross((cross) => !cross);
  };

  React.useEffect(() => {
    const handleClick = (event) => {
      // Check if the click is inside the Navbar container or the NavLinks container
      if (
        event.target.closest(".navbar-container") ||
        event.target.closest(".nav-links")
      ) {
        return;
      }

      // If the click is outside, close the NavLinks component
      setShowLinks(false);
      setCross(false);
    };

    // Add the click event listener to the document object
    document.addEventListener("click", handleClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <div className={`toggle-button${cross ? " active" : ""}`} onClick={toggleLinks}>
        <HamburgerLine className="hamburger-line" />
        <HamburgerLine className="hamburger-line" />
        <HamburgerLine className="hamburger-line" />
      </div>
      <NavLinks className="nav-links" show={showLinks}>
        <NavLinkWrapper className="navlink-wrapper" show={showLinks}>
          <a className="nav-link" href="#">
            About Us
          </a>
          <hr className="divider" />
          <a className="nav-link" href="#">
            Whitepaper
          </a>
          <hr className="divider" />
          <a className="nav-link" href="#">
            Roadmap
          </a>
          <hr className="divider" />
          <a className="nav-link" href="#">
            Team
          </a>
        </NavLinkWrapper>
      </NavLinks>
    </>
  );
};


const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="logo" href="https://www.airea51.xyz" />
      <NavbarLinks />
    </div>
  );
};

export default Navbar;
