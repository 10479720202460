import Navbar from '../navbar/Navbar';
import IntroText from '../introtext/IntroText';
import HomeImage from '../homeimage/HomeImage';
import './HomePage.css'

const Home = () => {
  return (
    <div className="home-container">
        <div className="background-image"></div>
        <HomeImage />
        <Navbar />
        <IntroText />
    </div>
  );
};

export default Home;